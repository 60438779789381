<template>
  <b-tabs id="account-page-tabs" class="mb-5" lazy>
    <b-tab>
      <template #title>
        <feather-icon icon="ShieldIcon" />
        <div class="font-small-1 tab-title">
          {{ $t("Security") }}
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <password-card />
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BRow, BCol, BTab, BTabs } from "bootstrap-vue";
import { notifyError } from "@methods";
import PasswordCard from "@/views/account/components/PasswordCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    PasswordCard,
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem("foravilaOwnersUserData"));
    },
    ownerUuid() {
      return this.userData.owner.uuid;
    },
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
  },
  created() {
    if (!this.owner) this.fetchOwner();
  },
  methods: {
    fetchOwner() {
      this.$store
        .dispatch("owner/fetchOwner", this.ownerUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchOwner.title"),
            this.$t("errors.fetchOwner.text")
          )
        );
    },
  },
};
</script>

<style lang="scss">
#account-page-tabs {
  .nav {
    padding-bottom: 10px;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    flex-wrap: nowrap;
    list-style: none;
    white-space: nowrap;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .nav-tabs::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  .nav-link {
    display: block !important;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .nav-link::after {
    background: none !important;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .nav-link::after {
      background: linear-gradient(
        30deg,
        #ffcd00,
        rgba(255, 205, 0, 0.5)
      ) !important;
    }
  }
  // Large devices (desktops, 992px and u
  @media (min-width: 992px) {
    .tab-title {
      font-size: 1rem !important;
    }
  }
}
</style>
